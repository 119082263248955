.InsurancePage {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    background: #F6F6F6; }
  &__title-item {
    margin: 0 !important; }
  &__edit-icon {
    font-size: 24px;
    margin-right: 32px; } }

.tabpane-string {
  margin: 0 15px 10px 0 !important;
  font-size: 18px;
  display: inline-block; }
