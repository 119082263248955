.ant-input.Input, .ant-input-affix-wrapper.ant-input-password.Input {
  background: #FFFFFF;
  border: 1px solid #EEEFF1;
  box-sizing: border-box;
  border-radius: 12px;
  height: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2E3138; }
