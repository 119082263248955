@import "shared/styles/vars.sass";

@keyframes shadow {
  50% {
    transform: scale(0.5);
    background-color: rgba(0, 0, 0, .1); } }

@keyframes spinner {
  50% {
    border-radius: 50%;
    transform: scale(0.5) rotate(360deg); }
  100% {
    transform: scale(1) rotate(720deg); } }

.Preloader {
  &__wrapper {
    width: 100%;
    height: 100vh;
    @include center-position;
    & .loader {
      span {}
      position: relative;
      &::before, &::after {
        content: '';
        position: relative;
        display: block; }
      &::before {
        animation: spinner 2.5s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
        width: 50px;
        height: 50px;
        background-color: #474747; }
      &::after {
        animation: shadow 2.5s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
        bottom: -30px;
        height: 5px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .2); } } } }

