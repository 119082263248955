.UIKitFloatingLabel, .ant-form .UIKitFloatingLabel {
  &__label {
    position: absolute;
    opacity: 0.3;
    top: 12px;
    left: 20px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    transition: 0.2s ease all;
    pointer-events: none;
    z-index: 105;

    &_float {
      top: 3px;
      font-size: 11px; }

    &_required {
      &::after {
        color: #d43c3f;
        content: '*';
        display: inline-block;
        margin: 0; } }

    &_disabled {
      color: #E0E0E0; } } }
