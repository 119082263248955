@import "shared/styles/vars";

.ant-row.ant-form-item {
  margin-bottom: 20px; }

.AuthorizationPage {
  &__wrapper {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row; }
  &__form-container {
    width: 100%;
    @include center-position; }
  &__field-password.ant-row.ant-form-item {
    margin-bottom: 25px; }
  &__splash-image {
    height: 100%;
    max-height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    object-fit: cover; }
  &__logo-image {
    position: absolute;
    z-index: 2;
    left: 30px;
    top: 50px; }
  &__form-wrapper {
    width: 500px;
    height: 300px;
    @include center-position; }
  &__entry-text {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px; }
  &__form {
    width: 90%;
    button {
      width: 100%; } } }
