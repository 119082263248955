.CreateRoutesModal {
  .ant-modal-content {
    border-radius: 16px; }

  h1 {
    font-size: 32px;
    font-weight: 600; }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px; }

  .ant-col.ant-form-item-label label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(#333, .3); }

  .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
    width: 250px;
    border: none;
    border-radius: 8px;
    background-color: #f8f8f8; }

  .ant-input-number, .ant-picker {
    width: 170px; }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 40px;
    border-radius: 12px;
    background-color: #f8f8f8; }

  .ant-select, .ant-input-number, .ant-picker {
    border: none;
    border-radius: 8px;
    background-color: #f8f8f8;
    height: 40px;
    color: #333;
    font-weight: 500;
    align-items: center;

    &:focus {
      border: 1px solid #d4d3d3; } }

  .ant-row.ant-form-item {
    &:not(:first-child) {
      margin-left: 12px; } }

  .ant-input-number {
    & .ant-input-number-input, .ant-select-selection-search-input {
      height: 40px; } }

  .ant-picker-input {
    flex-direction: row-reverse; }

  .ant-picker-suffix {
    margin-left: 0;
    margin-right: 4px; }

  .ant-btn {
    background-color: #fff;
    border: 1px solid #333;
    height: 60px;
    width: 206px;
    border-radius: 100px;
    color: #333;
    font-size: 16px;
    font-weight: 500; }

  .ant-btn-primary {
    background-color: #333;
    color: #fff;
    margin-left: 8px; }

 }  // .ant-select-item-option-selected:not(.ant-select-item-option-disabled)

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(#333, .1) !important; }
