@mixin cancel-btn() {
  background: #FFFFFF {
    color: #333333;
    border: 1px solid rgba(51, 51, 51, 0.2); } }

.ant-btn.Button, .ant-btn-primary.Button, .ant-btn.ant-btn-dangerous {
  &__delete {
    height: 60px;
    width: 135px;
    color: #FF5405;
    border-color: rgba(255, 84, 5, 0.2);
    border-radius: 100px; }

  &__auth {
    background: #F2F3F4;
    text-transform: uppercase;
    border-radius: 100px;
    height: 60px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(46, 49, 56, 0.5);
    border: 0; }

  &__send, &__send-large, &__cancel, &__cancel-large, &__save {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-radius: 100px;
    height: 48px;
    letter-spacing: -0.02em;
    font-size: 16px;
    border: 0; }

  &__send, &__send-large {
    background: #333333;
    color: #FFFFFF; }

  &__cancel, &__cancel-large {
    background: #FFFFFF;
    color: #333333;
    border: 1px solid rgba(51, 51, 51, 0.2); }

  &__save, &__save-large {}


  &__cancel-large {
    width: 135px;
    height: 60px; }

  &__send-large {
    height: 60px;
    width: 135px; } }
