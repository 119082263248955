.UIKitInput {
  position: relative;

  .ant-input-number {
    width: 100%; }

  .ant-input-number-input {
    height: auto; }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;

    &:focus {
      box-shadow: none; } }

  .ant-input, .ant-input-affix-wrapper, .ant-input-number {
    padding: 16px 20px;
    font-size: 15px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 0;

    &:focus {
      box-shadow: none; }

    & .ant-input-suffix {
      margin-top: -10px;
      font-size: 20px; } } }
