.EditingForm {
  &__title {
    color: #2E2E34;
    opacity: 0.4;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: uppercase; }

  &__container-building {
    width: 25%;
    margin-left: 10px;
    margin-right: 10px; }

  &__container-index {
    width: 70%; }

  &__row-container {
    width: 102%; }

  &__input {
    width: 100%; }

  &__input_margin-left {
    margin-left: 10px; }

  &__input_country {
    margin-left: 10px; }

  &__button-submit-styles:not(:first-child) {
    margin-left: 8px; } }
