@import "shared/styles/vars.sass";

.Sidebar {
  &__wrapper {
    position: fixed;
    width: 299px;
    height: 100%;
    background: #fff !important;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
    &:hover {
      overflow-y: overlay;
      @include custom-scrollbar; } }
  &__translate {
    display: flex;
    align-items: center;
    padding-left: 20px; }
  &__translate-icon {
    width: 20px;
    height: 20px; }
  &__select-input {
    width: 100px !important;
    margin: 10px 0 !important; }
  &__create-button-container {
    flex-grow: 2; }
  &__create-button {
    display: flex;
    justify-content: center;
    width: 100%; }
  &__bottom-action-container {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 299px; } }

.sidebar-footer {
  display: flex;
  align-items: center;
  gap: 60px; }
