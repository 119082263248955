$white: #fff;
$light-grey: #f0f0f0;

@mixin center-position() {
  display: flex;
  justify-content: center;
  align-items: center; }

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #FAFAFA;
    border: none;
    transition: .3s; }

  &::-webkit-scrollbar-track {
    margin: 10px;
    transition: .3s; }

  &::-webkit-scrollbar-thumb {
    background: rgba(92, 92, 92, 0.2);
    border-radius: 5px;
    transition: .3s; } }
