.EditingCompanyInfo {
  &__wrapper {
    width: 100%; }

  &__change-language-button {
    margin-left: auto !important;
    color: dodgerblue !important; }

  &__info-company {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center; }

  &__title-company {
    font-size: 70px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 16px; } }




