.PilotsPage {
  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center; }

  &__title-container {
    display: flex;
    flex-direction: row;
    margin-top: 57px;
    margin-bottom: 20px; }

  &__title-pilots {
    font-size: 72px;
    letter-spacing: -0.03em;
    margin-right: 12px;
    text-transform: uppercase;
    margin-bottom: 0; }

  &__plane-count {
    font-size: 72px;
    opacity: 0.15;
    margin-bottom: 0; }

  &__title {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around; }

  &__reset-button {
    height: 40px !important; }

  &__plus-image {
    margin-left: 15px; }

  &__button-container {
    margin-top: 25px;
    padding-top: 25px; } }
