.EditableTable {
  .ant-table-row {
    background: #ffff; }

  .ant-table-column-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5; }

  .ant-table-column-has-sorters::before {
    content: none!important; }

  .cell-value-wrap {
    font-size: 16px;
    font-weight: 500; }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    padding: 19px; }

  .ant-table {
    border-radius: 13px;
    overflow: hidden; }

  &__pagination-container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    .ant-pagination {
      background: #ffff;
      padding: 10px 15px;
      border-radius: 12px; }

    .ant-pagination-prev {
      margin-right: 0px; }

    .ant-pagination-item-active a {
      color: rgba(51, 51, 51, 0.5);
      text-decoration-color: rgba(51, 51, 51, 0.5);
      outline-color: rgba(51, 51, 51, 0.5); }

    .ant-pagination-item.ant-pagination-item-active {
      background: #F6F6F6;
      border-radius: 0;
      border: 0;
      margin-right: 0; }

    .ant-pagination-item,
    .ant-pagination-item-link {
      border: none;
      margin-right: 0; }

    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none; }

    .ant-pagination-item.ant-pagination-item a,
    .anticon.anticon-right,
    .anticon.anticon-left {
      color: rgba(51, 51, 51, 0.5);
      margin-right: 0; }

    .ant-pagination-item.ant-pagination-item-active a {
      color: rgba(0, 0, 0, 0.85);
      margin-right: 0; } } }
