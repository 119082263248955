.RegistrationPage {
  &__submit-button {
    width: 100%; }

  &__select-field {
    width: 200px !important; }

  &__back-button {
    margin-top: 4px;
    margin-right: 10px; } }
