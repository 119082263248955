@import "./vars.sass";

.ant-layout-sider, .ant-layout-content {
  background: #E0E0E0; }

.ant-layout-content {
  padding: 20px 40px; }

.ant-layout-sider {
  height: 100vh !important;
  border-right: 1px solid $light-grey !important; }

.button {
  font-size: 24px;
  border: none;
  background: none;
  margin: 0 0 10px 5px;
  cursor: pointer; }

.delete-contract-button {
  margin-right: 40px; }

.edit-icon {
  font-size: 24px; }

.info-group {
  margin-bottom: 35px; }

.title-wrapper {
  display: flex;
  justify-content: space-around;
  width: 30%;
  margin: auto; }

.title-info-block {
  display: flex;
  flex-direction: column;
  align-items: center; }
.info-block {
  display: flex;
  flex-direction: column;
  align-items: center; }

.autocomplete-input {
  flex: 1 !important;
  margin-bottom: 20px; }

.reset-button {
  height: 40px; }

.cell-string {
  margin: 0; }

.action-string {
  margin: 0;
  color: #40a9ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline; } }

.editable-row {
  height: 60px !important; }

.custom-cell {
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
  color: #40a9ff; }

.ant-tag {
  margin: 3px 3px 0 0 !important; }

.form-item {
  margin: 0;
  padding: 0; }

.ant-form-inline {
  .inline-form-item {
    padding-top: 12px; } }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none  !important;
  margin: 0 !important; }

.PhoneInputInput {
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: .2s;
  &:hover {
    border-color: #40a9ff; }
  &:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    box-shadow: 0 0 0 2px rgba(87,168,233, .2); } }

.mt-20 {
  margin-top: 20px; }

.w500 {
  width: 470px !important; }

.adding-button {
  height: 40px !important;
  margin-left: 20px; }

.back-arrow {
  font-size: 20px; }

.form-wrapper {
  margin-top: -21px;
  width: 100%;
  height: 100vh;
  @include center-position;
  flex-direction: column; }

.form-container {
  width: 700px;
  padding: 20px 0 30px;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
  @include center-position; }

.form {
  width: 90%; }

.go-back {
  position: absolute;
  top: 10px;
  left: 10px; }

.hidden {
  display: none; }
.changeContractStatusButton {
  margin: 7px 0 0 20px; }

.empty-string {
  color: #bfbfbf;
  font-style: italic; }

.company-link {
  &:hover {
    text-decoration: underline; } }

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important; }
