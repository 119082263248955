.UIKitSelect {
  .ant-select {
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
    height: 55.57px;
    padding: 16px 20px; }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    padding: 0; }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none; } }

.ant-select-dropdown {
  left: 340px!important; }
