.AddingModal {
  &__button-submit-styles:not(:first-child) {
    margin-left: 8px; }

  &__button-reset-styles, &__button-submit-styles {
    width: 50%; }

  .ant-modal-content {
    padding: 30px;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.16);
    border-radius: 16px; }

  .ant-modal-body {
    padding: 0; }

  .ant-modal-footer {
    display: none; }

  .ant-col.ant-form-item-label {
    text-align: left; }

  .ant-table-pagination.ant-pagination {
    border-radius: 16px; }

  .ant-row.ant-form-item.inline-form-item {
    display: flex;
    flex-direction: column;
    width: 100%; }

  #rcDialogTitle0 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 18px; }

  .ant-modal-header {
    border-bottom: 0;
    padding: 0; }

  .ant-modal-close {
    display: none; }

  .ant-form-item-required {
    font-size: 16px; }

  .ant-row.ant-form-item.inline-form-item {
    padding-bottom: 0; }

  .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    max-width: 100%; }

  .ant-input-number.input, .ant-picker,
  .ant-form-item-control-input-content,
  .ant-picker, #RegisterNumber {
    width: 100%;
    background: #F8F8F8;
    border-radius: 8px;
    border: 0; }

  .ant-select-selector,
  .ant-select:not(.ant-select-customize-input),
  .ant-select-selection-search,
  .ant-input-number-input-wrap {
    background: #F8F8F8!important;
    border-radius: 8px!important;
    border: 0!important; }

  .ant-form-item-label > label {
    opacity: 0.3; } }

.ant-row.AddingModal {
  &__container-buttons {
    margin-top: 30px;
    width: 100%;
    flex-wrap: nowrap; } }
