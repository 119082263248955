.PhoneInput {
  width: 100%;

  .phone-input {
    padding: 16px 20px;
    font-size: 15px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 0;
    height: 55.57px;
    width: 100%; }

  .PhoneInputCountry {
    display: none; }

  .phone-input.PhoneInput.PhoneInput--focus,.PhoneInputInput:focus {
    box-shadow: none; }

  .PhoneInputInput {
    border: 0; } }

